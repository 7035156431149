import {FC} from "react";

type WomanProps = {
  resultColor: string
}
const Woman: FC<WomanProps> = ({resultColor}) => {
  switch (resultColor) {
    case 'roos':
      return (<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193 680">
        <path d="M118.002 615.697c24.284 13.521 49.962 23.147 75.328 33.371M192.724 2C44.969 42.94-18.979 251.267 15.106 324.118M99.042 604.012c-24.596-16.995-44.033-39.08-52.513-66.278-43.85-140.699 142.157-74.753 125.05-19.872-13.527 43.374-110.807-33.703-103.777-111.577 7.03-77.874 127.895-58.157 107.155 2.921-20.448 60.237-149.444-50.854-157.96-82.787" stroke="#D8A89B" stroke-width="3.671" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.92 18.35"/>
        <path d="M47.76 321.329c.66 2.413 2.991 13.145 2.991 13.145 9.104-.11 14.923-3.762 20.686-4.979l2.441-1.261s2.037-12.924 1.56-14.938c-.495-2.013-8.663-11.241-9.012-10.932-.349.31-19.841-3.784-19.841-3.784s-1.432 13.189 1.174 22.749Z" fill="#12152D"/>
        <path d="M78.54 329.894c4.919-.509 7.892-.111 12.132-2.479 2.827-8.055 0-21.93 0-21.93-5.837-22.019-14.61-20.337-14.61-20.337s-1.799 21.001-3.23 45.52c2.973-.288 2.753-.465 5.708-.774Z" fill="#212440"/>
        <path d="M20.961 590.978s-9.6 5.665-12.169-1.372c-.514-1.416 9.985-144.661 9.948-148.113-.036-3.452 9.618 136.097 2.221 149.485Z" fill="#E5D8D5"/>
        <path d="M89.884 467.517s7.654 88.474 9.838 114.211c0 0-2.992 6.107-10.48 7.878-7.49 1.748.642-122.089.642-122.089Z" fill="#E2CFCC"/>
        <path d="M82.945 340.14c-.422-3.408-5.727-16.398-2-31.712 3.725-15.314-25.33 3.386-17.713 14.65 0 0 3.047 3.208 3.928 8.697.532-.155-.624-.864-.129-.709 1.817.598 1.707 5.82 1.45 7.901-.183 1.416 1.946.287 1.45 1.549 1.983 1.416 4.791 2.81 7.838 2.323 0 0 5.543.332 5.176-2.677v-.022ZM77.44 653.184s12.72-1.128 5.873-24.519l11.288-3.298s3.965 14.96 5.892 21.709c2.423 8.498 2.698 13.499 2.074 16-.643 2.501-40.748 2.456-40.748 2.456s-.734-4.647 15.639-12.37l-.019.022Z" fill="#AA6960"/>
        <path d="M73.97 646.988s-16.905-.332-17.694 18.744c0 0 11.839 2.368 27.954 1.704 0 0 21.09-.797 20.778-2.545 0 0 1.358-3.585-.404-4.647l-22.118.84s-3.083-.066-5.873-.553c0 0 .587-10.069-2.625-13.543h-.018Z" fill="#EEE0D9"/>
        <path d="M28.028 396.924s6.516-4.935 10.83-10.512l13.967-22.926 9.453 23.878-21.145 21.953-13.087-12.393h-.018Z" fill="#AD6061"/>
        <path d="M47.447 567.985s32.066 51.097 38.435 66.079c0 0 7.966 24.498 10.5 15.38 2.55-9.117-7.306-54.438-9.619-65.592-2.294-11.153-19.07-32.464-30.102-39.28 0 0-16.96-3.164-9.214 23.413Z" fill="#AA6960"/>
        <path d="M79.275 473.625s-8.168 39.213-16.226 80.529c0 0-5.543 2.789-15.656-2.5 0 0-3.304-22.772-4.773-40.852-1.468-18.079-3.817-38.77 4.7-56.673 11.6-24.343 31.973 19.496 31.973 19.496h-.018Z" fill="#E07E66"/>
        <path d="M59.819 337.44s3.45-.089 8.168-6.086c4.809-6.108 8.81-8.675 8.333-14.628-.477-5.93-1.469-19.496-4.185-25.117-2.735-5.643-17.4-5.444-23.109 3.718-5.708 9.162 5.617 32.907 10.811 42.113h-.018Z" fill="#BB7569"/>
        <path d="M80.982 298.912c2.129 10.932-3.524 21.886-12.592 24.453-9.085 2.567-18.152-4.248-20.282-15.18-2.129-10.932 3.525-21.887 12.592-24.454 9.085-2.567 18.153 4.249 20.282 15.181Z" fill="#BB7569"/>
        <path d="M48.475 309.778s.422-4.603 1.23-6.683l6.424 11.662s10.426 7.081 16.226-.266l.33-3.341s-.092-4.802-.22-5.001c-.129-.2-7.434-6.374-10.132-7.104-2.68-.708-8.058-1.859-9.49-1.859-1.43 0-4.019-.708-4.698 0-.68.708-.991 2.39-.991 3.054 0 .664.275 3.297.275 3.297s.257 3.807 1.046 6.219v.022Z" fill="#AA6960"/>
        <path d="M51.925 320.201s.606 5.975-.385 8.188c0 0 0 .332.753.199.77-.133 3.524-.996 3.524-.996l-3.892-7.391Z" fill="#BB7569"/>
        <path d="M90.874 358.529c-1.101-4.293-2.074-8.387-3.946-11.751-.33-.575-1.725-2.854-2.056-3.054-2.184-1.372-1.633-1.416-2.992-3.695-1.1-1.859-2.312-3.607-3.046-5.665l-7.911 2.39c-.9 6.373-5.25 11.374-10.683 11.441-9.801 19.252-7.966 43.13-7.966 43.13s-2.643 6.396-2.386 16.619c.257 10.224 1.726 27.33 1.726 27.33 14.72 8.963 30.358 0 30.358 0-4.515-9.582-2.918-27.175.404-38.372 3.322-11.198 12.206-23.812 8.48-38.373h.018Z" fill="#ECE3DF"/>
        <path d="M60.46 347.73s-13.894 17.903-17.895 23.236c-4.662 6.196-2.625 21.576 11.582 18.124 0 0 9.214-1.881 6.332-41.36h-.018Z" fill="#ECE3DF"/>
        <path d="M81.092 477.166s-24.632 59.241-30.946 77.763c0 0-14.188 12.614-22.301 3.607 0 0 2.147-44.126 5.69-61.786 3.56-17.659 9.195-37.686 22.135-51.185 17.603-18.368 25.422 31.623 25.422 31.623v-.022Z" fill="#F69E8B"/>
        <path d="M61.287 437.443s18.979-6.506 20.686-2.191c3.983 10.047 13.435 34.434 1.028 48.818-12.408 14.384-33.48-3.651-31.093-29.941 2.386-26.29 9.397-16.686 9.397-16.686h-.018Z" fill="#F69E8B"/>
        <path d="M53.89 385.062s-32.947 16.31-35.113 50.965c-2.68 42.798 45.942 50.942 57.285 14.649 0 0-1.358-47.733-22.172-65.636v.022Z" fill="#ECE3DF"/>
        <path d="M36.361 402.39s3.56.996 5.341 3.696c0 0 1.028 1.327.551 1.659-.477.332-.863.576-2.882.62 0 0 3.267 2.899 4.424 5.709 1.138 2.811-.918 1.616-1.193 1.572-.276-.045-1.707-.399-1.707-.399s.715 1.195-1.248 1.239c-1.983.045-3.561-.331-4.149-.708-.587-.376-3.982-3.341-4.074-4.536-.074-1.195-.11-5.533 1.064-6.484 1.193-.952 3.873-2.412 3.873-2.412v.044Z" fill="#E5D8D5"/>
        <path d="M29.129 547.914s4.625-2.7 9.085 3.762c4.46 6.462 5.727 20.094 0 21.908-5.726 1.815-13.766-13.034-9.085-25.67Z" fill="#F69E8B"/>
        <path d="M34.892 572.61s35.59 49.88 42.583 74.533c0 0 11.435 6.639 8.884-2.479-3.781-13.454-10.59-47.114-17.418-66.632-5.489-15.668-14.262-15.668-24.137-29.587 0 0-17.639-2.39-9.912 24.188v-.023Z" fill="#BB7569"/>
        <path d="M18.686 438.018s48.841-14.362 62.204-5.643c0 0 8.938 16.819 8.938 36.204 0 19.386 2.735 100.668 3.947 123.992 0 0-1.928 3.961-9.673 2.125-7.746-1.837-5.91 1.195-9.214 3.275-3.304 2.08-16.722 3.452-23.146-.244-6.424-3.717-9.434-2.235-17.235 1.195-7.8 3.431-14.482-1.814-14.482-4.226s-1.321-55.944-1.321-156.678h-.018Z" fill="#ECE3DF"/>
        <path d="M89.02 458.223c-2.074-13.853-7.507-24.63-8.094-25.737-3.139-9.936-1.578-25.405 1.45-35.584 3.322-11.198 12.206-23.812 8.48-38.373-.569-2.235-1.12-4.426-1.762-6.484-4.075-4.293-11.105-7.015-20.667 6.396 0 0-10.426 19.584-23.825 21.001 0 0-3.194.708-4.258-1.549-.129 3.297.79 6.727 3.047 9.006 1.046.974 2.44 1.815 4.276 2.39 0 0 14.262 3.143 15.804 25.449 1.542 22.307-19.933 40.342-35.04 43.042l7.196 140.634c6.974-3.076 10.003-4.227 16.097-.709 6.424 3.718 19.841 2.324 23.145.244 3.304-2.08 1.468-5.112 9.214-3.275 7.746 1.836 9.673-2.125 9.673-2.125-1.193-23.302-3.946-104.606-3.946-123.992 0-3.563-.312-7.059-.79-10.356" fill="#E5D8D5"/>
        <path d="m80.045 428.901-38.27-35.938c-.972.995-1.945 1.947-2.954 2.899 1.523 2.19 4.552 6.041 7.764 7.081 0 0 25.146 24.829 31.57 28.082l2.735 1.306-.845-3.474v.044Z" fill="#E5D8D5"/>
        <path d="m41.133 398.207-4.185 1.328v2.656h-1.89s-.092-3.187 0-3.298c.092-.11.642-2.722.642-2.722l3.8-1.15 1.633 3.164v.022Z" fill="#E5D8D5"/>
        <path d="M71.4 517.264s-.073 66.677 3.451 80.707c0 0 2.184-1.328 4.057-4.846 1.872-3.519-7.508-75.861-7.508-75.861ZM36.452 598.037s-1.652-28.812 1.946-36.69c3.597-7.901 6.791 34.19 6.791 34.19s-2.973.11-8.737 2.5Z" fill="#E2CFCC"/>
        <path d="M64.15 667.391s17.988-11.197 3.506-42.688l11.16-10.843s2.624 14.473 5.451 22.284c2.368 6.573 7.214 10.49 9.49 18.877.367 1.372-9.105 10.976-18.447 21.864-2.937 3.43-26.394.996-26.394.996s-6.442 1.859 15.253-10.49h-.019Z" fill="#BB7569"/>
        <path d="M71.492 364.615s.532-11.109 7.617-12.703c7.085-1.593 12.647 6.772 11.931 16.819-.496 7.081-8.113 14.384-10.242 15.114-2.129.731-10.04-7.059-9.306-19.23Z" fill="#F69E8B"/>
        <path d="M90.691 368.93s-1.505 21.598-6.663 56.851c0 0-9.562-2.014-12.628-6.573 0 0 2.038-28.967 1.726-43.971-.22-10.667 17.547-6.307 17.547-6.307" fill="#F69E8B"/>
        <path d="M81.33 333.434c-1.064-3.718-8.48-1.46-12.095 1.04-.257 6.374-3.708 11.729-8.902 13.322-.019.022-.037.067-.074.089-1.505 2.146-8.663 11.286 5.268 2.722 6.48-3.784 10.224-6.551 13.638-7.392 2.294-.575 5.01.399 7.764 3.541 0 0-3.488-5.931-5.599-13.344v.022Z" fill="#BB7569"/>
        <path d="M85.442 345.318s.954-2.102 5.671 10.865c2.643 7.281 2.13 23.701.845 33.704 0 0-2.643 25.272-2.754 38.195-.11 12.902 4.57 48.531 6.094 71.744 0 0-18.501 9.007-37.223 3.939 0 0 3.028-64.529.367-92.191 0 0-2.404-1.284-1.542-5.466.863-4.205 6.37-37.975 12.28-52.292 0 0 7.011-14.473 16.28-8.476l-.018-.022Z" fill="#801E1F"/>
        <path d="M85.938 412.149s-8.499-4.625-19.86-10.401c-13.876-7.037-20.319-14.583-20.319-14.583s-7.25-.177-5.837 4.802c0 0 22.301 24.52 31.46 31.844 9.16 7.303 12.114 5.754 12.114 5.754l2.442-17.438v.022Z" fill="#BB7569"/>
        <path d="M35.755 403.297s2.515-10.29-5.084-8.542c-11.013 2.523-5.543 16.686-2.496 15.978 2.771-.642 1.193 1.173 7.599-7.436h-.019Z" fill="#C08386"/>
        <path d="M29.588 401.881c.698-.531 1.432-.885 2.184-1.151.661.044 1.45.111 2.35.244 0 0 3.763.907 5.286 3.54a74.56 74.56 0 0 0 2.166 3.475s-.991.664-2.46-1.571c0 0-1.853-1.439-2.68-1.616 0 0 .918 3.187 1.818 4.758.917 1.571 4.478 4.935 4.478 4.935s.643.42.184 1.261c-.46.841-5.048-2.346-5.048-2.346l-2.294-2.522 1.817 3.496s2.019.93 2.46 1.372c.44.421.055.996-.056 1.018-.11 0-1.596-.265-1.596-.265s-.202.796-.57.796c-.367 0-2.22-.619-2.22-.619l-.9-.908-1.505-1.482 1.579 2.102s.532.752.367 1.261c-.184.509-.55.554-.55.554l-2.497-2.656s-4.552-5.046-4.387-10.135c.496-2.235.826-2.589 2.074-3.519v-.022Z" fill="#BB7569"/>
        <path d="M64.664 658.916s-20.062.686-20.062 19.806c0 0 14.152 1.637 33.26 0 0 0 24.998-2.081 24.539-3.807 0 0 1.432-3.673-.715-4.625l-26.193 2.191s-3.67.111-6.993-.199c0 0 .202-10.091-3.8-13.366h-.036Z" fill="#F1EAE8"/>
        <path d="M77.365 313.075s-1.046 2.014-1.175 5.2c1.083.775 2.643 4.493 3.745 7.104.055 0 .66-4.249.715-4.249.44-4.426.643-8.962.624-11.397 0 0 4.59-12.481 1.69-18.699-2.9-6.219-18.135-17.328-33.37-2.589 0 0-.477 2.279.294 9.648 0 0 12.26 29.056 20.576 31.734 3.542 1.129-2.276-14.561 4.038-19.651 0 0 2.11-2.213 2.881 2.899h-.018Z" fill="#212440"/>
        <path d="M73.915 310.397s1.01-3.496 3.36-2.766c2.349.73 2.11 5.4 1.21 7.524-.917 2.125-2.074 3.475-2.661 2.7-.587-.752-2.258-4.647-1.89-7.48l-.019.022Z" fill="#BB7569"/>
        <path d="M74.704 311.017s.55-2.811 2.68-2.08c2.129.73.88 5.289-.165 5.842 0 0 .293-1.77-.771-1.77-1.065 0-1.78-.93-1.725-1.992h-.019Z" fill="#AA6960"/>
        <path d="M47.668 333.545c.238 1.261.514 2.146.79 2.744a9.272 9.272 0 0 1 1.56.243c-4.222-27.108-.056-38.992-.056-38.992s8.04-10.025-.073-8.144c-6.406 1.483-5.03 26.069-4.754 29.322.165 1.948 2.551 14.827 2.551 14.827h-.018Z" fill="#212440"/>
        <path d="M54.68 327.348s.146-.42-.11-1.172c-.258-.753-1.598-2.988-1.598-2.988s.019 2.988-.22 3.917c0 0 0 .31.202.421.202.11 1.597-.089 1.725-.178Z" fill="#AA6960"/>
        <path d="m45.796 388.205-6.81-1.239c-1.284.199-2.936.641-4.864.597-1.743-.044-.697.044-2.881-.288 0 0-1.065 1.904 2.624 2.413 1.56.221 5.488.597 5.654.663.165.067 6.277-2.168 6.277-2.168v.022ZM35.59 397.278l-.936 2.191s1.983 2.279 2.386 3.275c.404.996.037 1.283-.091 1.306-.129.022-3.598-3.674-3.598-3.674l.184-3.341 1.67-1.55.385 1.793Z" fill="#BB7569"/>
        <path d="m35.187 395.972-2.772 2.191-.752 2.988s.385 1.637-.202 2.921c0 0-.184.332-.551.376l-.734-2.457.22-4.824 3.708-5.156 1.101 3.983-.018-.022Z" fill="#BB7569"/>
        <path d="M35.15 388.05s-3.23 1.416-4.442 1.571c0 0-1.102 2.279-2.074 3.342 0 0-.22 2.478-.551 3.031 0 0 .386 1.218 1.028-.243.642-1.438.606-2.169.606-2.169s.807-1.084 1.156-1.482c.349-.399 4.387-1.018 4.387-1.018l-.11-3.032Z" fill="#BB7569"/>
        <path d="M35.094 389.643s-3.12 2.479-4.57 2.877c0 0-.918 3.585-2 5.023 0 0-.294 2.081-.441 3.209 0 0 .569.664 1.065-.464.77-1.749.642-2.103.642-2.103s1.744-3.098 2.11-3.651c.386-.553 3.782-1.66 3.782-1.66l-.606-3.231h.018Z" fill="#BB7569"/>
        <path d="M45.76 387.165s-7.93.774-10.573.907c0 0-3.414 5.864-.037 10.135 0 0 1.193-.774 3.102-1.084 2.02-.332 4.772.73 8.021-1.77 2.331-1.793-.514-8.166-.514-8.166v-.022Z" fill="#BB7569"/>
        <path d="M71.437 394.556s12.132 4.16 17.749 2.368c0 0 1.23-10.379 1.927-15.911.697-5.533 4.68-27.817-8.15-31.247-12.811-3.43-16.85 16.796-11.526 44.79Z" fill="#C08386"/>
        <path d="M88.93 394.578s2.55 3.43 1.743 5.002c-.808 1.571-1.579 2.567-1.45 3.142.11.575.66 2.501.66 2.921 0 .42-1.376 2.434-1.725 2.788-.349.354 0 2.147 0 2.147l-1.541 1.792s.66 1.284 0 3.209c-.661 1.925-10.61-9.471-14.574-10.777 0 0-2.038-.509-1.23-2.169l-.44-3.363s1.027-1.328 1.651-1.926c.643-.575-2.147-1.77-.77-3.784 1.376-2.014 10.26-3.74 17.675.974v.044Z" fill="#C08386"/>
      </svg>)
    case 'groen':
      return (<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 121.39 244.84"
                   enableBackground={'new 0 0 121.39 244.84'} xmlSpace="preserve">
          <path fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} d="M65.59 214.8h0"/>
          <path d="M72.73 218.32c13.23 6.11 27.22 10.46 41.04 15.08"
          fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} strokeDasharray={'0,7.9535'}/>
          <path fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} d="M117.53 234.67h0"/>
          <g>
    <path d="M46.77 0h0"/>
            <path d="M40.36 4.93C18.66 22.9-1.91 53.64 16.67 86.56"
                  fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} strokeDasharray={'0,8.0868'}
            />
            <path fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} d="M18.76 90.02h0"/>
  </g>
          <path
            d="M62.39 213.04c-13.4-7.68-23.99-17.66-28.61-29.95-23.89-63.58 77.45-33.78 68.13-8.98-7.37 19.6-60.37-15.23-56.54-50.42s69.68-26.28 58.38 1.32c-11.14 27.22-81.42-22.98-86.06-37.41"
            fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} strokeDasharray={'0,8.0868'} strokeMiterlimit={10}
          />
          <g>
    <path fill={'#ebbaaf'}
          d="M46.24 234.44s2.51-26.78-.77-41.6c0 0-1.18-3.78 1.12-11.16 3.81-12.25 2.75-32.31 1.32-35.12-.54-1.06-2.12-2.3-4.18-3.58-6.18-3.82-16.2-7.68-16.2-7.68s-6.97 11.22-6.94 18.22c.03 6.71 11.6 38.02 12.04 42.83.44 4.82.68 17.65 8.45 38.09 0 0-.29 3.36-.72 4.05s-2.25 3.02-4.32 3.8c-2.07.78-8 .37-8.46 2.53h20.9s.29-3.23-.52-5.35c-.8-2.13-1.72-5.03-1.72-5.03zM88.56 141.12c-.6-.62-5.02-1.76-5.02-1.76s-7.06-14.34-11.53-21.76c-1.84-3.06-2.99-16.74-4.32-20.4-2.53-6.96-9.59-6.33-9.59-6.33l1.18 5.26s2.82 21.39 6.39 27.74c2.81 5 15.24 17.82 15.24 17.82.51 1.1 2.88 1.6 4.54 3.42 0 0 1.67 1.7 4.84 1.47.6-.05-1.13-4.84-1.73-5.46z"/>
            <path fill={'#ebbaaf'}
                  d="m61.85 134.35-18.02-.59-11.89-4.95c3.94-4.03 4.37-5.11 4.37-5.11-1.37-7.89-.56-12.67-2.1-19.99-1.27-6.05 2.15-13.54 2.15-13.54 2.25-.25 4.33-.42 6.25-.54a2.3 2.3 0 0 0 2.15-2.05c.16-1.46.31-3.41.43-6.04l8.41 1.35h.03s-.11 1.04-.13 2.44v.04c-.01.86.01 1.85.11 2.82 0 .04.01.08.01.12.09.79.82 1.24 1.6 1.4 2.57.51 5.9 1.37 5.9 1.37s-1.82 15.56-2.62 25.44c-.34 4.18-.5 7.35-.27 7.89.23.53 1.08 2.83 1.89 5.08.87 2.45 1.73 4.86 1.73 4.86z"/>
            <path fill={'#d8a99c'}
                  d="M53.59 88.19c-3.5-.47-8.41-6.65-8.41-6.65l8.43 1.12-.03.23c-.04.42-.12 1.36-.1 2.45v.04c-.01.86.01 1.85.11 2.81z"/>
            <path fill={'#ebbaaf'}
                  d="M56.12 83.09s-9.82 3.28-11.77-2.87c-1.95-6.15-4.53-9.87 1.66-12.22 6.19-2.35 8.16-.39 9.31 1.5 1.15 1.89 3.31 12.58.8 13.59z"/>
            <path d="M52.6 80.38s.36.71 2.22.14c0 0 1.56-.48 1.31-1.3-.01 0-1.69-.22-3.53 1.16z" fill={'#fff'}/>
            <path fill={'#d8a99c'}
                  d="m60.1 129.49-4.18.77s0-2.23-.43-3.6-5.21-4.7-5.21-4.7l8.2-5.44c-.34 4.18-.5 7.35-.27 7.89.22.52 1.07 2.82 1.89 5.08z"/>
            <path
              d="M54.44 60.01c-3.5-3.6-9.01 2.06-12.3 3.87-.66-2.16-2.53-5.37-7.65-2.93-7.34 3.5-3.73 6.42-4.83 7.83-1.1 1.41-1.89 5.34.9 7.2 2.78 1.86 2.8 2.9 4.1 3.09.98.14 2.83-.55 3.97-1.87 1.51 1.1 2.86 1.73 3 2.86.26 2.06 2.78 2.03 3.48 1.92.83-.14.24-3.34-.34-3.54-2.01-.72-2.25-4.2-.14-3.29 2.12.91 3.68-.89 3.85-1.79.18-.89-1.28-3.54 1-4.04 2.29-.5 3.05-.19 4.61-1.33 0 0 4.48-3.74.35-7.98z"
              fill={"#212440"}/>
            <path
              d="M45.64 84.84c-.82-.01-1.61-.3-2.26-.83a3.789 3.789 0 0 1-1.37-2.53c-.16-1.47.54-2.91 1.78-3.66a.13.13 0 1 1 .14.22c-1.16.7-1.81 2.03-1.66 3.41.1.93.56 1.77 1.28 2.35.72.59 1.62.85 2.52.75.91-.1 1.73-.56 2.3-1.29.57-.73.83-1.65.72-2.58-.21-1.92-1.92-3.31-3.8-3.1a.12.12 0 0 1-.14-.12.12.12 0 0 1 .12-.14c2.02-.23 3.86 1.27 4.09 3.33.11 1-.16 1.98-.78 2.77-.61.79-1.49 1.28-2.47 1.39-.17.02-.32.03-.47.03z"
              fill={'#feeb95'}/>
            <path fill={'#d8a99c'}
                  d="M42.05 196.47c-.29-3.13-1.93-5.55-3.67-5.39-1.74.16-2.91 2.83-2.62 5.96.29 3.13 1.93 5.55 3.67 5.39 1.74-.16 2.91-2.83 2.62-5.96zM46.59 181.67c-13.06-8.22-22.95-34.05-22.95-34.05l20.09-4.65c2.06 1.27 3.64 2.52 4.18 3.58 1.43 2.81-.12 22.35-1.32 35.12z"/>
            <path
                  fill={'#ebbaaf'}
                  d="M78.94 238.14c-.58-1.27-2.91-4.46-2.91-4.46-5.87-35.66-13.47-36.2-13.61-40.34-.04-1.37.63-5.75 1.51-11.22 1.78-11.11 4.39-26.74 3.46-30.88-.42-1.87-1.1-4.17-1.83-6.45-1.69-5.24-3.72-10.44-3.72-10.44l-23.12 7.2s7.24 40.9 8.21 51.26c.78 8.29 23.32 40.87 23.32 40.87-.29 1.87 1.21 5.15 1.09 6.88-.12 1.73.92 4.26.92 4.26s10.13.05 10.59 0c.47-.05-3.33-5.41-3.91-6.68z"/>
            <path fill={'#d8a99c'}
                  d="M56.99 197.89c-1.08-2.96-3.28-4.87-4.92-4.28s-2.1 3.48-1.02 6.44c1.08 2.96 3.28 4.87 4.92 4.28 1.63-.6 2.09-3.48 1.02-6.44zM63.93 182.12c-4.36-8.33-10.7-29.9-10.7-29.9s7.23-3.34 12.33-7.43c.74 2.29 1.41 4.58 1.83 6.45.93 4.14-1.68 19.77-3.46 30.88z"/>
            <path fill={'#801e20'}
                  d="M67.39 151.24s-6.92.23-13.82 2.12c-4.59 1.26-9.18 3.25-11.71 6.4 0 0-6.58-7.11-14.41-8.88-2.14-.48-4.38-.57-6.6 0 0 0 .2-7.26 9.5-19.98.62-.84 1.27-1.71 1.97-2.6 0 0 8.93 5.15 23.58 1.96 1.34-.29 2.72-.65 4.15-1.1 0 0 3.36 8.1 4.44 11.35.77 2.27 2.9 10.73 2.9 10.73z"/>
            <path d="M45.29 125.77c-.2-.02-.4.36-.45.85-.05.49.06.9.26.92.2.02.4-.36.46-.85.04-.48-.08-.89-.27-.92z"
                  fill={"#e37c3c"}/>
            <path fill={'#6d1919'}
                  d="M67.39 151.24s-6.92.23-13.82 2.12c-2.24-7.19 2.27-22.87 2.34-23.1 1.34-.29 2.72-.65 4.15-1.1 0 0 3.36 8.1 4.44 11.35.76 2.27 2.89 10.73 2.89 10.73z"/>
            <path fill={'#ebbaaf'}
                  d="M41 98.81c0-1.96-1.5-8.43-1.5-8.43s-2.99-.74-5.47.53c-6.52 3.36-19.92 19.65-19.4 21.98.52 2.33 9.33 15.57 12.53 18.01 0 0-3.2 4.04-3.2 4.78 0 .73.65 3.8 1.42 3.84.78.04 1.04-2.5 1.64-2.98.6-.48 2.76-1.37 3.07-2.2.3-.83.27-3.44.27-3.44s-7.09-16.17-7.18-17.48C23.04 111.51 41 100.77 41 98.81z"/>
            <path
              d="M61.81 110.99v.01c-.06.45-.17.93-.37 1.55-1.23 3.87-3.04 9.55-3.04 9.55s-2.94.54-6.81.8c-.45-4.74 10.22-11.91 10.22-11.91z"
              fill={"#e14438"}/>
            <path fill={'#801e20'}
                  d="M61.27 105.1c.36 3.57.69 4.62.54 5.89v.01c-.06.45-.17.93-.37 1.55-1.23 3.87-3.04 9.55-3.04 9.55s-2.94.54-6.81.8c-5.27.36-12.26.21-15.89-2.51 0 0 .15-1.03-2.19-8.67-.96-3.12.29-5.06.73-6.3.15-.43.21-.78.05-1.06-1.05-1.81-2.55-3.55-3.92-4.94-1.76-1.79-3.28-2.99-3.28-2.99s5.18-5.65 7.51-6.36c2.07-.63 7.49-.42 7.49-.42s-.16 12.75 5.26 14.71c0 0 7.74-1.55 8.6-14.52 0 0 8.02.84 9.52 2.6 2.24 2.63 2.88 7.38 2.88 7.38s-7.21 3.98-7.08 5.28z"/>
            <path fill={'#6d1919'}
                  d="M34.24 105.42c.15-.43.21-.78.05-1.06-1.05-1.81-2.55-3.55-3.92-4.94 1.52-.67 5.7-2.33 6.69-.7 1.2 1.99-2.71 6.58-2.82 6.7z"/>
  </g>
</svg>)
    case 'geel':
      return (<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 197.71 234.67"
                  enableBackground="new 0 0 197.71 234.67" xmlSpace="preserve">
        <path fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} d="M112.25 214.8h0"/>
        <path d="M119.38 218.32c13.23 6.11 27.22 10.46 41.04 15.08"
              fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} strokeDasharray={'0,7.9535'}/>
        <path fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} d="M164.19 234.67h0"/>
        <g>
    <path fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} d="M93.42 0h0"/>
          <path d="M87.01 4.93C65.31 22.9 44.75 53.64 63.32 86.56"
                fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} strokeDasharray={'0,8.0868'}
                />
          <path fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} d="M65.42 90.02h0"/>
  </g>
        <path
          d="M109.04 213.04c-13.4-7.68-23.99-17.66-28.61-29.95-23.89-63.58 77.45-33.78 68.13-8.98-7.37 19.6-60.37-15.23-56.54-50.42 3.83-35.19 69.68-26.28 58.38 1.32-11.14 27.22-81.42-22.98-86.06-37.41"
          fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} strokeDasharray={'0,8'} strokeMiterlimit={10}/>
        <g>
    <path
      d="M167.35 145.37s-6.22 4.62-7.43 4.34c-1.21-.28 4.54-4.99 4.54-4.99-2.17-1.04-8.9-.39-8.9-.39s-2.24 7.23-3.35 6.66c-1.11-.57 2.31-6.3 2.31-6.3-2.41-.15-5.37 1.65-5.37 1.65-4.05 3.2.27 8.23.27 8.23s-.83 2.49-3.39 4.76c0 0-3.65 2.7-3.91 7.53l.66.06s1.05-4.5 2.96-6.62c.07-.08.18-.19.31-.32.31-.29.53-.51.68-.67.95-.91 2.26-2.08 2.9-2.19 0 0-.03-.12-.03-.32l.03.32c.22 2.54 5.15 4.77 8.19 5.22 3.04.46 2.1-.79 1.05-2.64s-4.36-2.68-5.11-3.53c-.76-.85.81-.85 2.64-.02 1.83.84 2.08 3.06 5.02 4.47 2.94 1.41 7.35-3.47 7.35-3.47-2.34-.12-5.68-2.66-5.56-3.44.12-.78 5.67 1.34 6.62 1.44.95.1 3.11-1.08 3.44-4.9.34-3.81-5.92-4.88-5.92-4.88z"
      fill={'#58aaa2'}/>
          <path
            d="M134.33 148.24s6.87-3.84 3.66-9.06c0 0-2.56-3.2-5.31-4.03 0 0 1.46 7.78 0 7.96-1.46.18-1.01-8.79-1.01-8.79s-7.23-3.48-10.07-3.2c0 0 4.49 7.6 3.02 7.41-1.46-.18-6.5-7.87-6.5-7.87s-7.41-1.37-8.6 3.02.73 6.59 1.83 6.87c1.1.27 8.15.18 7.96 1.1-.18.92-4.94 2.38-7.6 1.56 0 0 2.93 7.23 6.77 6.87 3.84-.37 5.03-2.75 7.41-2.93 2.38-.18 4.12.46 2.93 1.1-1.19.64-5.22.21-7.14 1.84-1.92 1.63-3.48 2.64.09 3.37s9.98.27 11.26-2.47l1.3-2.75z"
            fill={'#7bcabc'}/>
          <path
            d="M136.16 154.92c-1.92-3.57-1.83-6.68-1.83-6.68-2.2-6.96-21.42-13.18-21.42-13.18 24.44 9.89 20.14 15.93 20.14 15.93.66.38 1.65 2.22 2.33 3.62.1.24.25.57.48 1.02.09.2.17.37.22.49 1.26 3.15.59 8.59.59 8.59l.76.2c1.7-5.48-1.27-9.99-1.27-9.99z"
            fill={'#6ec0b2'}/>
          <path
            d="M145.26 142.94s-9.05-5.07-4.83-11.95c0 0 3.38-4.22 7-5.31 0 0-1.93 10.26 0 10.5 1.93.24 1.33-11.58 1.33-11.58s9.53-4.59 13.27-4.22c0 0-5.91 10.02-3.98 9.77 1.93-.24 8.57-10.38 8.57-10.38s9.77-1.81 11.34 3.98c1.57 5.79-.97 8.69-2.41 9.05-1.45.36-10.74.24-10.5 1.45.24 1.21 6.52 3.14 10.02 2.05 0 0-3.86 9.53-8.93 9.05-5.07-.48-6.64-3.62-9.77-3.86-3.14-.24-5.43.6-3.86 1.45 1.57.84 6.88.28 9.41 2.43 2.53 2.15 4.59 3.48-.12 4.45-4.71.97-13.15.36-14.84-3.26l-1.7-3.62z"
            fill={'#6cc0b6'}/>
          <path
            d="M173.49 125.56s-25.34 8.21-28.24 17.38c0 0 .12 4.1-2.41 8.81 0 0-3.91 5.93-1.68 13.16l1.01-.27s-.88-7.17.78-11.32c.07-.15.17-.37.29-.64.3-.59.51-1.03.64-1.34.9-1.85 2.21-4.27 3.07-4.77 0-.01-5.68-7.98 26.54-21.01z"
            fill={'#56aa9b'}/>
          <path fill={'#222440'} d="M144.95 180.57c9.79 0 17.73-7.94 17.73-17.73h-35.46c0 9.79 7.94 17.73 17.73 17.73z"/>
          <path fill={'#141620'}
                d="M149.31 180.43c-.72.09-1.46.14-2.2.14-9.79 0-17.73-7.94-17.73-17.73h4.4c.01 9.05 6.78 16.51 15.53 17.59z"/>
          <path fill={'#141620'}
                d="M160.74 179.29h-31.57c-1.21 0-2.19.98-2.19 2.19h35.94c0-1.22-.98-2.19-2.18-2.19zM126.64 162.84h36.44v.54h-36.44z"/>
  </g>
        <g id="girl_2_4_">
    <path fill={'#222440'}
          d="M103.64 134.5c2.08-10.86-5.2-14.47-5.53-17.09-.34-2.62-.56-8.54-4.92-12.68-4.23-4.01 1.08-20.97-13.81-19.31.6-.46 1.24-.58 1.24-.58-1.15-.03-1.81.4-2.15.7-.16.03-.33.05-.5.08 0 0-3.23-1.79-7.7 1.23-4.46 3.02-4.91 12.01-3.9 15.89 1 3.88-4.77 7.01-5.4 9.92-.63 2.9 1.94 3.91-.85 6.59-2.79 2.68-7.46 7.26-6.15 13.8.44 2.21 1.99 7.84 6.08 12.68-.3.69-.56 2 .34 3.86.47.96.64 1.71.67 2.27.88-.6 1.92-1.54 2.1-2.72 0 0 1.1 2.11.88 4.03-.11.99 1.5 3.6 4.67 3.08.65-.11 19.72-3.38 23.2-4.93.02.11.05.21.08.31.89 2.77 2.89 1.53 3.44 1.13 1.55-1.13.83-2.47 3.46-3.21 1.19-.33 1.95-2.69 2.22-3.79 5.66-6.94 2.07-8.87 2.53-11.26z"/>
          <path fill={'#ebbaaf'}
                d="M138.2 138.55c-2.01-1.05-2.88 1.21-3.65 1.55-.76.35-2.98-.14-3.94 1.32-.65.99-1.83 1.82-1.83 1.82-6.15 3.57-14.92 6.17-16.59 6.26-.46.03-1.71-1.95-3.31-4.87-4.19-7.64-10.82-21.75-12.22-23.32-.57-.65-1.72-1.24-3.05-1.76v-.01s-.02 0-.04-.01c-3.11-1.2-7.17-1.96-7.23-1.97a3.2 3.2 0 0 1-2.29-2.88c-.16-2.47-.01-5.31-.01-5.31l-9.23.21c.26 1.88.08 4.03-.16 5.79-.14 1.04-.9 1.88-1.91 2.14-.02 0-4.93.97-8.06 2.4-.94.43-1.72.9-2.16 1.4-1.26 1.42-6.77 13.03-10.92 20.9-2.21 4.19-4.03 7.32-4.61 7.29-1.68-.09-10.45-2.68-16.59-6.26 0 0-1.18-.83-1.83-1.82-.95-1.45-3.17-.97-3.94-1.32-.76-.35-1.64-2.6-3.65-1.55-.72.38.56 3.46.56 3.46 1.24 2.9 3.9 2.84 5.04 2.68.22-.03.54.11.87.32.7.43 1.45 1.13 1.45 1.13s8.57 8.54 15.32 11.07c.03.01.05.02.08.03.06.02.12.05.18.07 1.15.41 2.25.64 3.23.6.05 0 .1-.01.15-.01.1-.01.2-.01.3-.03 3.21-.39 7.06-3.86 10.65-8.13.44-.52.87-1.05 1.29-1.58 1.93-2.42 3.75-5 5.31-7.35 1.15 6.7 3.12 14.79 3.62 16.82l.06.58c-2.46 4.02-5.96 9.85-5.96 9.85s35.51 1.59 35.18 0c-.34-1.59-5.03-9.55-5.36-10.56-.27-.81 1.16-7.69 2.55-14.12 1.42 2.02 2.97 4.1 4.57 6.03.29.36.59.71.89 1.05 3.41 3.93 7 7.03 10.03 7.4 6.67.8 19.26-11.74 19.26-11.74l-.05-.1.05.1s.46-.42.99-.81c.33-.24.7-.48 1-.58.02-.01.02-.01.03-.01.12-.04.23-.06.32-.05 1.14.16 3.8.22 5.04-2.68.01.02 1.29-3.06.57-3.44z"/>
          <path
            d="M110.09 176.37c-8.51 7.14-20.72 11.02-20.72 11.02s-9.83.19-20.97 0c-10.82-.18-21.41-13.52-21.41-13.52s9.02-.12 11.5-2.23c1.73-1.47 3.81-3.91 5.83-6.56 1.46-1.91 2.88-3.92 4.12-5.76 0 0 2.56 2.5 12.95 2.23 10.39-.27 12.92-1.42 12.92-1.42s1.23 1.51 2.84 3.41c1.95 2.3 4.47 5.18 6.1 6.7.72.67 6.84 6.13 6.84 6.13z"
            fill={'#ae6162'}/>
          <path d="M73.62 89.84s4.55-4.51 9.89.12c0 0-3.18-.67-9.89-.12z" fill={'#efa351'}/>
          <path
            d="M79.75 94.99s3.91-.07 4.8 1.24c.89 1.31 1.01 2.77 1.46 3.51.45.74.37 1.57.37 1.57s3.37-8.62-4.41-6.9c-.56.12-2.22.58-2.22.58z"
            fill={'#b6473d'}/>
          <path d="M31.24 147.84c.04-.01.08-.03.13-.04-.02.01-.07.02-.13.04z" fill={'#51559f'} />
          <path
            d="M110.09 176.37c-8.51 7.14-20.72 11.02-20.72 11.02s-9.83.19-20.97 0c-10.82-.18-21.41-13.52-21.41-13.52s9.02-.12 11.5-2.23c1.73-1.47 3.81-3.91 5.83-6.56.46.29 10.25 6.43 16.04 6.13 5.89-.31 16.62-7.56 16.79-7.67 1.95 2.3 4.47 5.18 6.1 6.7.72.67 6.84 6.13 6.84 6.13z"
            fill={'#9a5054'}/>
          <path fill={'#ebbaaf'}
                d="M111.26 175.43c-2.59 2.24-10.28 4.71-17.52 6.66-2.03.55-4.03 1.05-5.87 1.5-1.66.52-5.03 1.59-8.64 2.84-5.1 1.77-7.33.93-9.85.48-2.53-.45-5.38 1.32-9.12-.05-3.74-1.37-2.8-8.74-2.8-8.74 1.27-.83 6.19-1.07 9.53 1.32 3.33 2.39 7.8 1.95 7.8 1.95.17.02.58-.19 1.13-.53 0 0 3.24-1.9 9.22-6.66 1.92-1.53 4.13-3.36 6.6-5.52 4.72-4.11 8.78-9.52 13.48-10.52 8.01-1.7 10.86 1.41 10.86 1.41s1.99 9.97-4.82 15.86z"/>
          <path
            d="M93.74 182.09c-2.03.55-4.03 1.05-5.87 1.5-1.66.52-5.03 1.59-8.64 2.84-5.1 1.77-7.33.93-9.85.48-2.53-.45-5.38 1.32-9.12-.05-3.74-1.37-2.8-8.74-2.8-8.74 1.27-.83 6.19-1.07 9.53 1.32 3.33 2.39 7.8 1.95 7.8 1.95.17.02.58-.19 1.13-.53 0 0 3.24-1.9 9.22-6.66 1.14 2.23 3.43 5.4 7.81 7.46.3.15.56.29.79.43z"
            fill={'#ece3df'}/>
          <path fill={'#ebbaaf'}
                d="M98.88 179.9s-.17 6.42-2.52 7.18c-3.23 1.05-5.78-.63-8.45-.14-2.67.49-4.84 1.19-10.22-.69-3.57-1.25-6.92-2.32-8.77-2.9-.9-.24-1.84-.5-2.8-.77-7.15-2.02-15.28-4.71-17.88-7.14-6.29-5.89-3.88-16.34-3.88-16.34s2.05-2.61 9.45-.91c4.35 1 8.83 5.3 13.18 9.39 1.8 1.7 3.51 3.27 5.07 4.68 5.46 4.93 9.19 7.99 9.19 7.99l-.02.03c.57.36.98.56 1.16.54 0 0 2.84 1.13 7.78-.53 3.46-1.15 7.38-1.27 8.71-.39z"/>
          <path fill={'#d8a99c'} d="M74.93 111.56s1.82 4.22 4.82 3.97c2.99-.25 4.22-4.07 4.22-4.07l-9.04.1z"/>
          <path fill={'#222440'}
                d="M71.89 106.46s-1.43-2.98-2.3-6.45c-.63-2.48-.02-11.86 9.42-11.2 0 0 2.73.07 5.41 1.61 2.13 1.22 5.27 3.97 2.58 12.32l-.65 2.8-14.46.92z"/>
          <path fill={'#ebbaaf'}
                d="M85.08 110.44c-2.44 2.32-5.48 5.42-11.99-.34-1.27-1.13-1.72-3.43-1.79-5.91-.08-2.94-.24-4.16.09-5.85.4-2.02 2.13-2.93 5.72-3.24 6.6-.55 9.32.92 9.48 4.22.34 7.14.98 8.76-1.51 11.12z"/>
          <path fill={'#222440'}
                d="M74.93 92.24c-3.79.08-5.76 9.47-4.01 9.07s5.24-4.79 5.24-4.79c-.29 2.26-2.89 4.36-2.89 4.36 4.02-1.51 5.78-5.88 5.78-5.88s-.33-2.84-4.12-2.76zM86.18 94.59c-1.84-1.26-7.12.4-7.12.4s1.84.02 2.6 1.36c.75 1.34 3.21 5.16 5.33 6.39 0 0 1.03-6.89-.81-8.15z"/>
          <path fill={'#d8a99c'}
                d="M81.03 156.57c-.3 0-.55.44-.55.99s.24.99.55.99c.3 0 .55-.44.55-.99s-.25-.99-.55-.99zM131.26 145.33c-.53.39-.99.81-.99.81s-12.59 12.54-19.26 11.74c-3.03-.37-6.62-3.47-10.03-7.4-.3-.35-.6-.7-.89-1.05l.92-1.61 4.71.51s4.11 3.62 5.56 6.19c1.17 2.07 13.81-5.48 18.9-8.55.42-.24.78-.46 1.08-.64zM137.64 142.01c-1.24 2.9-3.89 2.84-5.04 2.68-.09-.01-.2.01-.32.05-.01 0-.01.01-.03.01 0 0-.57-1.42 2.78-2.35 3.35-.92 3.16-3.85 3.16-3.85.73.38-.55 3.46-.55 3.46zM60.11 148.18c-.43.54-.86 1.07-1.29 1.58-3.59 4.26-7.43 7.74-10.65 8.13l-.3.03c-.05 0-.1.01-.15.01-.96.03-2.05-.2-3.23-.6-.06-.02-.12-.04-.18-.07-.03-.01-.05-.02-.08-.03-6.75-2.53-15.32-11.07-15.32-11.07s-.76-.7-1.45-1.13c.48.23 1.02.51 1.62.81 5.98 3.1 17.05 9.51 19.2 7.93 3.02-2.22 7.04-7.61 7.04-7.61l4.79 2.02zM27.46 145.01c-.34-.21-.66-.35-.87-.32-1.14.16-3.8.22-5.04-2.68 0 0-1.28-3.09-.56-3.46 0 0 .19 3.08 2.43 3.66 2.17.57 3.95 2.7 4.04 2.8z"/>
          <path
            d="M96.67 121.31c-.57-.65-1.72-1.24-3.05-1.76v-.01s-4.58 5.52-14.16 5.6c-9.57.08-14.68-5.28-14.68-5.28l-.01.18-.09-.13c-.94.43-1.72.9-2.16 1.4-1.26 1.42-6.77 13.03-10.92 20.9.01.09.38 2.8 2.82 5.09 1.04.98 2.47 1.88 4.4 2.46 2.28-2.71 4.46-5.74 6.31-8.5.07-.11.14-.21.21-.32l.19.89 2.91 17.49c4.49 1.43 8.44 2.11 11.83 2.34.48.03.66-.12 1.12-.1l.29.17c8.6.27 13.15-2.41 13.15-2.41s.02-11.9.81-15.61c.01-.03.01-.07.02-.1 1.64 2.33 3.45 4.73 5.32 6.88 1.98-.43 3.54-1.23 4.74-2.15a11.54 11.54 0 0 0 3.16-3.71c-4.19-7.64-10.82-21.75-12.21-23.32z"
            fill={'#801e20'}/>
          <path fill={'#6d1919'}
                d="M54.42 147.3c1.04.98 2.47 1.88 4.4 2.46 2.28-2.71 4.46-5.74 6.31-8.5-1.79-.2-10.71 6.04-10.71 6.04zM95.64 143.57c1.64 2.34 3.47 4.75 5.35 6.91 1.98-.43 3.54-1.23 4.74-2.15-.01.01-8.12-4.8-10.09-4.76zM65.54 141.83l2.91 17.49c2.95 1.74 6.33 2.25 12.3 2.25-5.84-.93-13.58-17.44-15.21-19.74z"/>
          <path fill={'#6d1919'}
                d="M80.74 161.57c8.6.27 13.57-1.43 13.57-1.43s.54-12.72 1.33-16.42v-.14c0-.01-7.81 17.51-14.9 17.99z"/>
          <path
            d="M98.88 179.9s-.17 6.42-2.52 7.18c-3.23 1.05-5.78-.63-8.45-.14-2.67.49-4.84 1.19-10.22-.69-3.57-1.25-6.92-2.32-8.77-2.9-.9-.24-1.84-.5-2.8-.77 1.39-1.16 4.86-4.56 5.94-10.32 5.46 4.93 9.19 7.99 9.19 7.99l-.02.03c.57.36.98.56 1.16.54 0 0 2.84 1.13 7.78-.53 3.46-1.15 7.38-1.27 8.71-.39z"
            fill={'#f6f5f6'}/>
  </g>
</svg>)
    case 'oranje':
      return (<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 193.97 251.84"
                  enableBackground="new 0 0 193.97 251.84" xmlSpace="preserve">
        <path fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} d="M104.11 214.8h0"/>
        <path d="M111.24 218.32c13.23 6.11 27.22 10.46 41.04 15.08"
              fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} strokeDasharray={'0,7.9535'}/>
        <path fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} d="M156.05 234.67h0"/>
        <g>
    <path fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} d="M85.29 0h0"/>
          <path d="M78.88 4.93C57.18 22.9 36.61 53.64 55.19 86.56"
                fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} strokeDasharray={'0,8.0868'}/>
          <path fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} d="M57.28 90.02h0"/>
  </g>
        <path
          d="M100.91 213.04c-13.4-7.68-23.99-17.66-28.61-29.95-23.89-63.58 77.45-33.78 68.13-8.98-7.37 19.6-60.37-15.23-56.54-50.42 3.83-35.19 69.68-26.28 58.38 1.32-11.14 27.22-81.42-22.98-86.06-37.41"
          fill='none' stroke='#d8a99c' strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"} strokeDasharray={'0,8'} strokeMiterlimit={10}/>
        <g>
    <path fill={'#222440'}
          d="M105.11 61.33s-4.28-3.05-8.41-1.16-9.56 8.46-10.04 15.72c-.48 7.26-6.99 9.59-8.32 15.54-1.33 5.95 3.08 9.85 2.38 13.55-.69 3.7-4.55 10.05 4.45 12.22s28.81-5.87 28.55-13.59c-.26-7.72 3.52-10.13 2.39-13.02-1.13-2.9-5.31-7.8-3.22-10.69 2.11-2.9 2.77-18.73-7.78-18.57z"/>
          <path fill={'#f59191'}
                d="M114.48 172.83c-.21 1.83-.48 3.7-.79 5.61-.25 1.58-.48 4.21-.68 7.54-1.12 18.66-1.39 59.13-1.39 59.13h-5.56s-8.2-32.35-6.5-41.5c.31-1.7.5-3.32.62-4.82.5-6.64-.62-11.15-.62-11.15-1.74-10.11-2.37-20.16-2.53-28.42-.19-11.54.56-19.62.56-19.62l17.38-2.2c.02.01 2.08 15.08-.49 35.43z"/>
          <path fill={'#ea8787'}
                d="M114.48 172.83c-.21 1.83-.48 3.7-.79 5.61-.25 1.58-.48 4.21-.68 7.54-2.66 2.51-8.95 8.47-12.83 12.81.5-6.64-.62-11.15-.62-11.15-1.74-10.11-2.37-20.16-2.53-28.42.79-.91 1.76-1.39 2.91-1.35 7.52.22 12.79 7.07 14.54 14.96z"/>
          <path fill={'#f59191'}
                d="M111.83 98.31c-1.59 1.39-3.64 2.62-6.28 3.55-9.9 3.49-14.76-4.66-16.89-10.39 1.3-.95 2.58-1.27 3.33-1.45.72-.17 1.49-.31 2.13-.42 1.04-1 2.34-4.77 2.09-10.37l.49.08 8.26 1.29s-.02.76-.03 1.83c-.01.92 0 2.07.04 3.18.03.75.07 1.48.14 2.12.11.98.27 1.74.52 1.98.64.07 1.03.11 1.03.11v.01c.12.17 2.88 3.73 5.17 8.48z"/>
          <path fill={'#ea8787'}
                d="M105.11 87.74c-4.65-.07-7.29-4.25-8.41-6.59l8.23 1.29c-.01.92 0 2.07.04 3.18.03.75.07 1.48.14 2.12z"/>
          <path fill={'#f59191'}
                d="M105.19 84.92s-10.24-1.47-9.23-7.83c1.01-6.36.37-10.84 6.95-10.17 6.58.67 7.46 3.3 7.64 5.5.19 2.21-2.67 12.72-5.36 12.5zM81.31 140.23s-2.21 23.79 1.97 48.04c0 0 1.69 6.8 0 15.96s6.51 41.5 6.51 41.5h5.55s-.21-56.39 2.07-66.67c2.27-10.28 7.05-39.45 7.05-39.45l-23.15.62z"/>
          <path fill={'#801e20'}
                d="M119.87 175.4s-25.85 7.97-46.11.25c0 0 1-4.63 2.33-11.11 2.16-10.63 5.21-26.24 6.1-34.48.44-3.92 1.47-13.08.69-22.13v-.02c-.08-.95-.19-1.89-.31-2.84-1.45-11.04 5.46-14.91 10.4-15.26 0 0 4.61 3.34 9.28 2.91 4.67-.44 4.05-2.93 4.05-2.93s4.03-.17 4.59.79c.56.96 4.88 8.78 4.88 16.8 0 8.06 1.31 38.62 4.1 68.02z"/>
          <path fill={'#f59191'}
                d="M110.9 90.59s6.71 7.33 6.91 16.57c.2 9.24-.48 12.13-.96 17.11-.48 4.98-.75 8.81-4.09 18.57l-3.15-1.08s.03-14.95.96-19.29c.93-4.35.33-31.88.33-31.88z"/>
          <path fill={'#f6f5f6'}
                d="M95.37 243.1s1.47 3.99 2.81 4.61c1.34.62 2.48 1.99 2.17 4.13h-12.4s-.83-3.22 1.14-8.74c0 0 2.71-2.1 4.55-1.61 1.84.48 1.73 1.61 1.73 1.61zM112.04 243.1s1.47 3.99 2.81 4.61c1.34.62 2.48 1.99 2.17 4.13h-12.4s-.83-3.22 1.14-8.74c0 0 2.71-2.1 4.55-1.61 1.84.48 1.73 1.61 1.73 1.61z"/>
          <path fill={'#801e20'}
                d="M106.31 89.79s4.37-1.13 7.41 2.73 5.85 13.18 5.85 13.18-7.33 4.82-10.7 1.45c-3.36-3.38-2.56-17.36-2.56-17.36z"/>
          <path fill={'#222440'}
                d="M108.27 68.43s-3.32 4.63-6.94 5.11c-3.62.48-6.16 6.35-6.16 6.35s-1.42-9.24 1.2-12.54c2.62-3.29 11.05-1.57 11.9 1.08z"/>
          <path fill={'#222440'}
                d="M108.27 68.43s-.4 3.6.61 5.84c1.01 2.24 0 6.19 0 6.19s4.67-9.81 3.38-11.74c-1.28-1.93-3.99-.29-3.99-.29z"/>
          <path fill={'#f59191'}
                d="M112.76 142.84s-1.17 7.63-2.22 6.3c-1.14-1.44-2.61-4.34-.93-7.39 3.46-6.28 3.15 1.09 3.15 1.09z"/>
          <path fill={'#f59191'}
                d="M109.61 141.76s-1.12.56-1.47 1.03c-.34.47-.99 1.9-.18 2.1.4.1 1.32-1.49 1.25-.92-.08.56.4-2.21.4-2.21zM107.56 148.34s2.07 2.64 3.76 2.94c0 0 2.9-1.07 3.17-1.47.27-.4-2.8-3.71-2.8-3.71s-2.53-.82-4.13 2.24z"/>
          <path
            d="M92.55 105.69c-.37 4.28-4.11 42.97-16.46 58.84 2.16-10.63 5.21-26.24 6.1-34.48.44-3.92 1.47-13.08.69-22.13v-.02c1.1-1.83 2.16-2.8 3.09-2.6 4.98 1.05 6.29.74 6.58.39z"
            fill={'#6d1919'}/>
          <path d="M92.55 105.69c.02-.25.04-.39.04-.39s.13.2-.04.39z" fill={'#e75755'}/>
          <path fill={'#f59191'}
                d="M91.59 92.14s-9.43 1.93-11.11 6.88c-1.69 4.94 11.46 27.9 13.51 34.27 2.05 6.37 13.58 15.05 13.58 15.05l1.65-1.65s-7.6-14.51-8.7-16.68c-1.12-2.17-8.93-37.87-8.93-37.87z"/>
          <path fill={'#801e20'}
                d="M92.97 89.81s-12.22 1.34-12.78 7.69c-.56 6.35.53 12.7.53 12.7s12.79 3.54 15.17-.88c2.39-4.42-2.92-19.51-2.92-19.51z"/>
  </g>
</svg>)
    default:
      return <></>
  }
}

export default Woman
