import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as AWS from 'aws-sdk'
import {ConfigurationOptions} from "aws-sdk";
import {BrowserRouter as Router} from 'react-router-dom';

const {AWS_REGION, AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY} = process.env

const awsConfig: ConfigurationOptions = {
  region: AWS_REGION,
  secretAccessKey: AWS_SECRET_ACCESS_KEY,
  accessKeyId: AWS_ACCESS_KEY_ID
}

AWS.config.update(awsConfig)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Router>
);
