import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'
import CTA from './components/CTA/CTA'
import Woman from './components/svg/Woman'
import { DatabaseService, ResultRecord } from './services/db.service'
import styles from 'App.module.scss'

function App() {
  const [formResult, setFormResult] = useState<ResultRecord>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [maxCalls, setMaxCalls] = useState(0)
  const formId = searchParams.get('formId')
  const dbService = new DatabaseService()

  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

  const getFormResult = async (formId: string): Promise<ResultRecord> => {
    if (maxCalls > 5) {
      return { formId: '', formResult: '' }
    }

    const formResult = await dbService.getById(process.env.REACT_APP_DATABASE_TABLE, `pf#${formId}`)

    if (!formResult) {
      sleep(2000).then(() => {
        setMaxCalls(maxCalls + 1)
        return getFormResult(formId)
      })
    }

    return formResult
  }

  useEffect(() => {
    setLoading(true)
    sleep(500).then(() => {
      if (formId) {
        getFormResult(formId)
          .then((resp) => setFormResult(resp))
          .catch(console.error)
          .finally(() => setLoading(false))
      } else {
        setLoading(false)
        setFormResult(undefined)
      }
    })
  }, [formId])

  const content = {
    roos: {
      title: `Jouw score is ${formResult?.formResult.toUpperCase()}`,
      advice: [
        [
          'Een zwangerschap én een bevalling zijn als topsport voor je bekkenbodem! Die verdient nu extra aandacht en training! Je bekkenbodemspieren oefenen, is een toptip voor iedere zwangere en pas bevallen vrouw!'
        ]
      ],
      suggestion: [
        'Het ',
        <strong>The Pelvic Floor Workout</strong>,
        ' online info én oefenprogramma is zeker een aanrader voor jou! Leer meer over de uitdagingen die zwangerschappen en bevallingen met zich meebrengen. Begrijp je eigen lichaam, gebruik de oefeningen regelmatig en neem je eigen bekkenbodemgezondheid in handen!'
      ],
      discount: [
        'Gebruik snel deze ',
        <strong>40% kortingcode</strong>,
        ' en geniet van lifetime-toegang: ',
        <strong>SelfcheckRoos24</strong>
      ],
      description: [
        'Bijleren én oefenen! ',
        <strong>Om lastige problemen te vermijden en/of verminderen.</strong>,
        ' Je bent heus geen dramaqueen als je wat vragen stelt over je eigen lichaam! Contacteer ons of je (huis)arts en bekkenbodemkinesist voor meer tips!'
      ]
    },
    groen: {
      title: `Jouw score is ${formResult?.formResult.toUpperCase()}`,
      advice: [
        [
          'Jouw risico op ',
        <strong>bekkenbodemproblemen lijkt minimaal</strong>,
        '. Je hebt weinig of geen problemen. En als er al eens iets is, dan vind je het niet zo erg? Je hebt een groen profiel! Houden zo! Voorkomen is beter dan genezen!'
        ]
      ],
      suggestion: [
        'Het ',
        <strong>The Pelvic Floor Workout</strong>,
        ' online info én oefenprogramma is zeker een aanrader voor jou! Je bekkenbodem regelmatig oefenen is belangrijk voor iedere vrouw! Begrijp je eigen lichaam, gebruik de oefeningen regelmatig en neem je eigen bekkenbodemgezondheid in handen!'
      ],
      discount: [
        'Gebruik snel deze ',
        <strong>40% kortingcode</strong>,
        ' en geniet van lifetime-toegang: ',
        <strong>SelfcheckGroen24</strong>
      ],
      description: [
        'Bijleren én oefenen! ',
        <strong>Om lastige problemen te vermijden en/of verminderen.</strong>,
        ' Je bent heus geen dramaqueen als je wat vragen stelt over je eigen lichaam! Contacteer ons of je (huis)arts en bekkenbodemkinesist voor meer tips!'
      ]
    },
    geel: {
      title: `Jouw score is ${formResult?.formResult.toUpperCase()}`,
      advice: [
        [
          'Alle vragen beantwoord! Alvast een eerste goede stap. Jouw ',
        <strong>risico op problemen neemt licht toe</strong>,
        ', je hebt af en toe bekkenbodemproblemen en vindt dit misschien toch ook wel ',
        <strong>lastig</strong>,
        '! Je problemen komen voor bij heel veel vrouwen! Maar ze horen er niet zomaar bij!'
        ]
      ],
      suggestion: [
        'Bekkenbodemspieroefeningen zijn in jouw geval écht aan te raden als eerste oplossing! Het ',
        <strong>The Pelvic Floor Workout</strong>,
        ' online info én oefenprogramma is zeker iets voor jou! Met info en oefenvideo’s leer je al snel dat je er (zelf) iets aan kan doen. Je bekkenbodem is complex en de quickfix bestaat niet. Maar oefenen en je lichaam begrijpen helpt! Misschien heb je nadien nog verdere hulp nodig, maar de eerste stap is dan gezet! Durf te spreken met ons of je ',
        <strong>(huis)arts en bekkenbodemkinesist.</strong>
      ],
      discount: [
        'Gebruik snel deze ',
        <strong>40% kortingcode</strong>,
        ' en geniet van lifetime-toegang: ',
        <strong>SelfcheckGeel24</strong>
      ],
      description: [
        'Je bent heus geen dramaqueen als je wat vragen stelt over je eigen lichaam!'
      ]
    },
    oranje: {
      title: `Jouw score is ${formResult?.formResult.toUpperCase()}`,
      advice: [
        [
          'Alle vragen beantwoord! Alvast een eerste goede stap. Jouw ',
        <strong>risico op bekkenbodemproblemen is verhoogd</strong>,
        '. Je merkt verschillende bekkenbodemproblemen op en geeft aan dat die lastige problemen écht een negatieve impact op je hebben. Je problemen komen voor bij veel vrouwen, maar zijn niet normaal. Ze horen niet zomaar bij vrouw zijn. Je kan er iets aan doen!'
        ]
      ],
      suggestion: [
        'Bekkenbodemspieroefeningen zijn in jouw geval alvast aan te raden als eerste oplossing! Het  ',
        <strong>The Pelvic Floor Workout</strong>,
        ' online info én oefenprogramma zet je op de goede weg! Met info en oefenvideo’s leer je alvast wat je (zelf) kan doen om je problemen te verminderen.'
      ],
      discount: [
        'Gebruik snel deze ',
        <strong>40% kortingcode</strong>,
        ' en geniet van lifetime-toegang: ',
        <strong>SelfcheckOranje24</strong>
      ],
      description: [
        'Maar we raden je liefst ook aan om gespecialiseerde hulp te zoeken. Durf te spreken met ons of jouw (gespecialiseerde) ',
        <strong>arts en bekkenbodemkinesist</strong>,
        '. Als tips en oefeningen niet volstaan, bestaan er nog oplossingen (bv medische hulpmiddelen (incontinentiemateriaal, pessaria), medicatie en eventueel zelfs chirurgie). Geef niet op! Je kan je levenskwaliteit écht verbeteren!'
      ]
    }
  }

  return (
    <>
      <div className={classNames(styles.App, formResult?.formResult ? styles[formResult.formResult.toLowerCase()] : styles.default)}>
        <div className={styles['woman-wrapper']}>
          <Woman resultColor={formResult?.formResult.toLowerCase()} />
        </div>
        {!loading && !!formResult && (
          <>
            <div className={styles['content']}>
              <div className={styles['result-block']}>
                <h1>{content[formResult?.formResult.toLowerCase()].title}</h1>
              </div>

              <div className={styles['advice-block']}>
                <h2>Advies</h2>
                {content[formResult?.formResult.toLowerCase()].advice.map((adviceP, idx) => (
                  <p key={idx}>{adviceP}</p>
                ))}
              </div>

              <div className={styles['description-block']}>
                <p>{content[formResult?.formResult.toLowerCase()].suggestion}</p>
                <p>{content[formResult?.formResult.toLowerCase()].discount}</p>
                <p>{content[formResult?.formResult.toLowerCase()].description}</p>
              </div>

              <CTA
                text='Schrijf je in'
                filled={true}
                navigateTo='https://www.thepelvicfloor.be/oefenprogramma'
                resultColor={formResult?.formResult.toLowerCase()}
              />

              <div className={styles['website-cta-block']}>
                <h3>Nog vragen?</h3>
                <CTA
                  filled={false}
                  text='Check onze website'
                  navigateTo='https://thepelvicfloor.be/'
                  resultColor={formResult?.formResult.toLowerCase()}
                />
              </div>
            </div>
          </>
        )}

        {loading && !formResult && (
          <div className={styles['loading-text']}>
            <p>Gegevens worden opgehaald</p>
            <PulseLoader color={'#000'} size={10} speedMultiplier={0.5} />
          </div>
        )}

        {!loading && !formResult?.formResult && (
          <div className={styles['loading-text']}>
            <p>Geen resultaat gevonden.</p>
            <p>Via onderstaande knop kan je terug naar het formulier gaan.</p>
            <CTA text='Keer terug' filled={true} navigateTo='https://selfcheck.thepelvicfloor.be' resultColor='default' />
          </div>
        )}
      </div>
      {!!formResult && !loading && (
        <footer className={styles['footer']}>
          <CTA
            text='Schrijf je in'
            filled={true}
            navigateTo='https://www.thepelvicfloor.be/oefenprogramma'
            resultColor={formResult?.formResult.toLowerCase()}
          />
        </footer>
      )}
    </>
  )
}

export default App
