import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import { DynamoDBDocument } from '@aws-sdk/lib-dynamodb';

const {
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_ACCESS_KEY_ID,
  REACT_APP_AWS_SECRET_ACCESS_KEY,
} = process.env;

const marshallOptions = {
  convertEmptyValues: false,
  removeUndefinedValues: false,
  convertClassInstanceToMap: false,
};

const unmarshallOptions = {
  wrapNumbers: false,
};

const translateConfig = {
  marshallOptions,
  unmarshallOptions,
};

const ddbClient = new DynamoDBClient({
  region: REACT_APP_AWS_REGION!,
  credentials: {
    accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID!,
    secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY!,
  },
});

export class DatabaseService {
  private readonly ddb = DynamoDBDocument.from(ddbClient, translateConfig);

  public async getById(table: string, id: string): Promise<ResultRecord> {
    try {
      const result = await this.ddb.get({
        TableName: table,
        Key: {
          appId: id,
        },
      });
      return result.Item as unknown as ResultRecord;
    } catch (error) {
      throw error;
    }
  }
}

export type ResultRecord = { formId: string; formResult: string };
