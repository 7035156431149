import classNames from 'classnames'
import { FC } from 'react'
import styles from './CTA.module.scss'

type CtaProps = {
  filled?: boolean
  text: string
  navigateTo: string
  resultColor: string
}

const CTA: FC<CtaProps> = ({ filled, text, navigateTo, resultColor = 'default' }) => {
  const handleClick = (dest: string) => {
    window.open(dest, '_blank')
  }

  return (
    <button
      className={classNames(styles.btn, filled ? styles.filled : styles.bordered, styles[resultColor])}
      onClick={() => handleClick(navigateTo)}>
      {text.toUpperCase()}
    </button>
  )
}

export default CTA
